import axios from "axios";
//import { API_HOST } from "./API";
import { getBaseToken, getSecureToken } from "./User";

const API_HOST = "http://localhost:8080"

export const attemptLogin = async (email, password, onSuccess, onFailure) => {
  try {
   await (axios.post(
        `${API_HOST}/core-services/v1/users/login`, {},
        {
          headers: {
            email: email,
            password: password
          }
        }
      ).then(onSuccess).catch(onFailure)
    );
   } catch(e) {
     console.error(e);
     onFailure(e);
   }
}

export const registerUser = async (firstName, lastName, email, password, onSuccess, onFailure) => {
  try {
   await (axios.post(
        `${API_HOST}/core-services/v1/users`, {
            email: email,
            firstName: firstName,
            lastName: lastName
        },
        {
          headers: {
            password: password
          }
        }
      ).then(onSuccess).catch(onFailure)
    );
   } catch(e) {
     console.error("Error", e);
     onFailure(e);
   }
}

export const registerUserWithInvite = async (firstName, lastName, email, password, invite, onSuccess, onFailure) => {
  try {
   await (axios.post(
        `${API_HOST}/core-services/v1/users/accept-invite-and-create-user`, 
        {
          user: {
            email: email,
            firstName: firstName,
            lastName: lastName
          },
          invite: invite
        },
        {
          headers: {
            password: password
          }
        }
      ).then(onSuccess).catch(onFailure)
    );
   } catch(e) {
     console.error("Error", e);
     onFailure(e);
   }
}

export const acceptInvite = async (invite, onSuccess, onFailure) => {
  try {
   await (axios.post(
        `${API_HOST}/core-services/v1/users/accept-invite`, 
        invite,
        {
          headers: {
            tbtc: getBaseToken(),
            tstc: getSecureToken()
          }
        }
      ).then(onSuccess).catch(onFailure)
    );
   } catch(e) {
     console.error("Error", e);
     onFailure(e);
   }
}

export const sendInvite = async (emailToInvite, accountId, onSuccess, onFailure) => {
  try {
   await (axios.post(
        `${API_HOST}/core-services/v1/users/invite-user`, 
        {
          expectedEmail: emailToInvite,
          accountId: accountId
        },
        {
          headers: {
            tbtc: getBaseToken(),
            tstc: getSecureToken()
          }
        }
      ).then(onSuccess).catch(onFailure)
    );
   } catch(e) {
     console.error("Error", e);
     onFailure(e);
   }
}

export const logout = async (onSuccess, onFailure) => {
  try {
   await (axios.post(
        `${API_HOST}/core-services/v1/auth/logout`, {},
        {
          headers: {
            tbtc: getBaseToken(),
            tstc: getSecureToken()
          }
        }
      ).then(onSuccess).catch(onFailure)
    );
   } catch(e) {
     console.error(e);
     onFailure(e);
   }
}

export const doesHaveAccess = async (accountId, onHasAccess, onDoesNotHaveAccess) => {
  try {
   await (axios.get(
        `${API_HOST}/core-services/v1/users/account-access/${accountId}`,
        {
          headers: {
            tbtc: getBaseToken(),
            tstc: getSecureToken()
          }
        }
      ).then(r => {
        if (r.data.hasAccess) {
          onHasAccess();
        } else {
          onDoesNotHaveAccess();
        }
      })
      .catch(onDoesNotHaveAccess)
    );
   } catch(e) {
     console.error(e);
     onDoesNotHaveAccess();
   }
}

export const getListOfAccountsUserHasAccessTo = async (onSuccess, onFailure) => {
  try {
   await (axios.get(
        `${API_HOST}/core-services/v1/users/account-access?createIfNonExist=true`,
        {
          headers: {
            tbtc: getBaseToken(),
            tstc: getSecureToken()
          }
        }
      ).then(onSuccess)
      .catch(onFailure)
    );
   } catch(e) {
     console.error(e);
     onFailure(e);
   }
}
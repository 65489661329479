import logo from './logo.svg';
import './App.css';
import { useEffect, Suspense, lazy } from "react";
import { BrowserRouter as Router,  Routes, Route } from "react-router-dom";
import SidebarNavigation from './components/SidebarNavigation';
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'


function App() {

const ManageFeatures = lazy(() =>
  import("./pages/ManageFeatures")
);

const AccountPage = lazy(() =>
import("./pages/AccountPage")
);

const BillingPage = lazy(() =>
import("./pages/BillingPage")
);

const BillingUpgradeSuccessPage = lazy(() =>
import("./pages/BillingPageUpgrade")
);

const ApiTokensPage = lazy(() =>
import("./pages/ApiTokensPage")
);


const LoginPage = lazy(() =>
import("./pages/LoginPage")
);

const SignupPage = lazy(() =>
import("./pages/SignupPage")
);


// react query
const queryClient = new QueryClient();



  return (
    <QueryClientProvider client={queryClient}>
    <Router>
    <div className="App">
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/f" element={<SidebarNavigation><ManageFeatures /></SidebarNavigation>} />
          <Route path="/api-tokens" element={<SidebarNavigation><ApiTokensPage /></SidebarNavigation>} />
          <Route path="/account" element={<SidebarNavigation><AccountPage /></SidebarNavigation>} />
          <Route path="/billing" element={<SidebarNavigation><BillingPage /></SidebarNavigation>} />
          <Route path="/billing/upgrade-success/:sessionId" element={<SidebarNavigation><BillingUpgradeSuccessPage /></SidebarNavigation>} />

          <Route exact path="/login" element={<LoginPage />} />
          <Route exact path="/signup" element={<SignupPage />} />
          <Route path="" element={<SignupPage />} />
        </Routes>
        
      </Suspense>
    </div>
    </Router>
    </QueryClientProvider>
  );
}

export default App;
